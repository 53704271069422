import React from 'react'
import PT from 'prop-types'
import GoogleMapReact from 'google-map-react'
import cx from 'classnames'
import mapStyles from './fancyMapStyles.json'
import { MAP_KEY } from './constants'
import {
  getMapStyles,
  mapDistanceToMouse,
} from './helpers'
import { latLngShape } from './propTypes';
import './mpbamap.sass'
import Marker from './Marker'


export class MPBAMap extends React.Component {
  static propTypes = {
    crosshair: PT.bool,
    layoutExpanded: PT.bool,
    onMapChange: PT.func.isRequired,
    isMobileLayout: PT.bool.isRequired,
    markers: PT.arrayOf(latLngShape),
    center: latLngShape.isRequired,
    zoom: PT.number.isRequired,
  }

  static defaultProps = {
    crosshair: false,
    markers: [],
    layoutExpanded: true,
  }

  render() {
    const { crosshair, isMobileLayout, markers, center, zoom, onMapChange, layoutExpanded } = this.props

    return (
      <div
        className={cx('map-container', { 'crosshair-map': crosshair })}
        style={getMapStyles(layoutExpanded, crosshair, isMobileLayout)}
      >
        <GoogleMapReact
          center={center}
          zoom={zoom}
          bootstrapURLKeys={{
            key: MAP_KEY,
          }}
          options={{
            styles: mapStyles,
            gestureHandling: 'greedy',
            clickableIcons: false,
            zoomControl: !isMobileLayout,
            fullscreenControl: !isMobileLayout,
            mapTypeControl: !!crosshair,
          }}
          onChange={onMapChange}
          hoverDistance={10}
          distanceToMouse={mapDistanceToMouse}
          resetBoundsOnResize
        >
          {markers.map(marker => (
            <Marker
              key={`${marker.lat}${marker.lng}`}
              lat={marker.lat}
              lng={marker.lng}
            />
          ))}
        </GoogleMapReact>
      </div>
    )
  }
}

export default MPBAMap
