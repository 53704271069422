import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import './index.sass'
import App from './App'
// import registerServiceWorker from './registerServiceWorker'

Sentry.init({dsn: "https://a17ccc5038e44380b7b9b0bc331b7fb5@sentry.io/1523327"})

// clear localstorage token once
if (!localStorage.getItem('WAS_LOGIN_TOKEN_CLEARED')) {
  localStorage.clear()
  localStorage.setItem('WAS_LOGIN_TOKEN_CLEARED', 'true')
}

ReactDOM.render(<App />, document.getElementById('root'))
// registerServiceWorker()
