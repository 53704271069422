"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.goToParent = exports.getNewState = exports.getChangedData = exports.moveItemInArray = exports.slugFromRoute = exports.isEditing = void 0;

var _deepmerge = _interopRequireDefault(require("deepmerge"));

var _url = require("./url");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var isEditing = function isEditing(pathname, slug) {
  var isAddPage = (0, _url.getLastUrlSegment)(pathname) === 'add';
  var isEdit = !isAddPage && Boolean(slug);
  return isEdit;
};

exports.isEditing = isEditing;

var slugFromRoute = function slugFromRoute(route) {
  var segments = route.path.split('/');
  return segments[segments.length - 1];
};

exports.slugFromRoute = slugFromRoute;

var moveItemInArray = function moveItemInArray(array, fromIndex, toIndex) {
  if (toIndex >= array.length) {
    var delta = toIndex - array.length; // eslint-disable-next-line

    while ((delta -= 1) + 1) {
      array.push(undefined);
    }
  }

  array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
  return array;
};

exports.moveItemInArray = moveItemInArray;

var getChangedData = function getChangedData(key, value) {
  // small hack to store english content that is nested
  var shouldBeNested = key.indexOf('-') > -1;
  var obj = {};

  if (shouldBeNested) {
    var ojb2 = {};
    var key1 = key.split('-')[0];
    var key2 = key.split('-')[1];
    ojb2[key2] = value;
    obj[key1] = ojb2;
  } else {
    obj[key] = value;
  }

  return obj;
};

exports.getChangedData = getChangedData;

var overwriteMerge = function overwriteMerge(destinationArray, sourceArray, options) {
  return sourceArray;
};

var getNewState = function getNewState(changedData, state) {
  return {
    data: (0, _deepmerge.default)(state.data || {}, changedData, {
      arrayMerge: overwriteMerge
    })
  };
};

exports.getNewState = getNewState;

var goToParent = function goToParent(history, pathname) {
  return function () {
    history.push("/".concat((0, _url.getContentTypeSlug)(pathname)));
  };
};

exports.goToParent = goToParent;