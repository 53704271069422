"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "deleteAction", {
  enumerable: true,
  get: function get() {
    return _delete.default;
  }
});
Object.defineProperty(exports, "fetch", {
  enumerable: true,
  get: function get() {
    return _fetch.default;
  }
});
Object.defineProperty(exports, "patch", {
  enumerable: true,
  get: function get() {
    return _patch.default;
  }
});
Object.defineProperty(exports, "post", {
  enumerable: true,
  get: function get() {
    return _post.default;
  }
});
Object.defineProperty(exports, "removeFromCategory", {
  enumerable: true,
  get: function get() {
    return _removeFromCategory.default;
  }
});

var _delete = _interopRequireDefault(require("./delete"));

var _fetch = _interopRequireDefault(require("./fetch"));

var _patch = _interopRequireDefault(require("./patch"));

var _post = _interopRequireDefault(require("./post"));

var _removeFromCategory = _interopRequireDefault(require("./removeFromCategory"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}