import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import { SERVER_URL } from 'admin-modules/build/constants'
import Form from 'bknd-modules/build/Form/Form'
import withFormSubmit from 'bknd-modules/build/Form/withFormSubmit'
import { textValidation } from './validations'

const common = {
  type: 'text',
  initialValue: '',
  placeholder: '',
  validation: textValidation,
}

const addEnglish = fields => {
  let withEn = []
  fields.forEach(f => {
    withEn.push(f)
    withEn.push({
      ...f,
      id: `${f.id}-en`,
      label: `${f.label} EN`,
    })
  })
  return withEn
}

const formConfig = {
  isSecured: true,
  submitUrl: `${SERVER_URL}/api/texts/main/`,
  // english field has always -en suffix!
  fields: addEnglish([
    // home
    {
      id: 'home-text',
      label: 'Homepage text',
      ...common,
    },
    // got tip
    {
      id: 'got-tip-headline',
      label: 'Mám tip nadpis',
      ...common,
    },
    {
      id: 'got-tip-text',
      label: 'Mám tip text',
      ...common,
      type: 'textarea',
    },
    {
      id: 'got-tip-thanks-headline',
      label: 'Mám tip nadpis po uspesnom odoslani',
      ...common,
    },
    {
      id: 'place-detail-suggestion',
      label: 'Detail miesta, link na formular',
      ...common,
    },
    {
      id: 'place-detail-comment-text',
      label: 'Mas k tomu co dodat text',
      ...common,
    },
    // other than input values are more difficult to translate
    // not worth it now, will do it if it is really needed

    // {
    //   id: 'got-tip-title-label',
    //   label: 'Mám tip názov label',
    //   ...common,
    // },
    // {
    //   id: 'got-tip-about-label',
    //   label: 'Mám tip o mieste label',
    //   ...common,
    // },
    // {
    //   id: 'got-tip-email-label',
    //   label: 'Mám tip email label',
    //   ...common,
    // },
    // {
    //   id: 'got-tip-email-info-text',
    //   label: 'Mám tip email vysvetlujuci text pod',
    //   ...common,
    // },
  ])
}

const Success = () => (
  <div className="succes-msg">
    <p>Vybavená vec, uložené, dikíčko</p>
  </div>
)

class Texts extends Component {
  state = {
    formConfig,
    isFetched: false,
  }

  componentDidMount() {
    axios.get(`${SERVER_URL}/api/texts`)
      .then(({ data }) => {
        const newFields = this.state.formConfig.fields.map(f => {
          const found = data.find(d => d.slug === f.id)
          
          if (!found) return f
          
          const initialValue = found.text
          return {
            ...f,
            initialValue,
          }
        })

        this.setState(state => ({
          formConfig: {
            ...state.formConfig,
            fields: newFields,
          },
          isFetched: true,
        }))
      })
      .catch(console.log)
  }

  render() {
    const { title } = this.props
    const { isFetched } = this.state
    const SubmitForm = !isFetched ? null : withFormSubmit(
      Form,
      this.state.formConfig,
      Success,
    )

    return (
      <Grid className="admin-item texts-section">
        <Row>
          <Col sm={8} smOffset={2}>
            <h2>{title}</h2>
            {!isFetched ? 'Loading...' : <SubmitForm />}
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default Texts
