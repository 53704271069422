"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _formik = require("formik");

var _elementReact = require("element-react");

require("element-theme-default");

var helpers = _interopRequireWildcard(require("./helpers"));

var _propTypes2 = require("./propTypes");

var _Field = _interopRequireDefault(require("./Field"));

require("./Form.css");

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var Form = function Form(_ref) {
  var beforeSubmitSlot = _ref.beforeSubmitSlot,
      config = _ref.config,
      onSubmit = _ref.onSubmit,
      submitButtonText = _ref.submitButtonText;
  return _react.default.createElement(_formik.Formik, {
    initialValues: helpers.getInitialValues(config),
    validate: helpers.getValidation(config.fields),
    onSubmit: onSubmit
  }, function (_ref2) {
    var isSubmitting = _ref2.isSubmitting,
        errors = _ref2.errors,
        touched = _ref2.touched;
    return _react.default.createElement(_formik.Form, {
      className: "bknd-form"
    }, config.fields.map(function (f) {
      var uploaderProps = f.type === 'imageUpload' && {
        onImagesChange: config.onImagesChange,
        imagesList: config.imagesList
      };
      return _react.default.createElement(_Field.default, _extends({
        key: f.id,
        error: errors[f.id],
        isTouched: touched[f.id],
        config: f
      }, uploaderProps));
    }), beforeSubmitSlot, _react.default.createElement(_elementReact.Button, {
      nativeType: "submit",
      disabled: isSubmitting
    }, submitButtonText));
  });
};

Form.propTypes = {
  beforeSubmitSlot: _propTypes.default.element,
  config: _propTypes.default.shape({
    fields: _propTypes2.fieldsProp
  }),
  onSubmit: _propTypes.default.func.isRequired,
  submitButtonText: _propTypes.default.string
};
Form.defaultProps = {
  submitButtonText: 'Submit'
};
var _default = Form;
exports.default = _default;