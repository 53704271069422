export const getLanguageFromPath = (pathname) => {
  const fragments = pathname.split('/')
  // english pages always start with /en
  return (fragments[1] === 'en') ? 'en' : 'sk'
}

export const mapDistanceToMouse = ({ x, y }, { x: mouseX, y: mouseY }) => Math.sqrt(
  (x - mouseX) *
  ((x - mouseX) + (y - mouseY)) *
  (y - mouseY),
)

export const getMapStyles = (layoutExpanded, crosshair, isMobileLayout) => {
  if (crosshair) {
    // map in admin style in css
    return {}
  }

  // desktop
  if (!isMobileLayout) {
    return { height: '100%' }
  }

  // mobile
  const wh = window.innerHeight
  return {
    height: (layoutExpanded) ? wh : wh * 0.4,
  }
}
