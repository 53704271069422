"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fieldsProp = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var fieldsProp = _propTypes.default.arrayOf(_propTypes.default.shape({
  id: _propTypes.default.string,
  type: _propTypes.default.oneOf(['text', 'textarea', 'email', 'imageUpload']),
  label: _propTypes.default.string.isRequired,
  initialValue: _propTypes.default.string.isRequired,
  validation: _propTypes.default.func
}));

exports.fieldsProp = fieldsProp;