import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Col, Row } from 'react-bootstrap'
import AdminHead from 'admin-modules/build/components/AdminHead/AdminHead';
import ItemsInCategory from 'admin-modules/build/components/Category/ItemsInCategory'
import 'admin-modules/build/components/Category/Category.css'
import { TranslatableTitleAndSlug } from 'admin-modules/build/components/Form';
import withContentData from 'admin-modules/build/hoc/withContentData'
import { isEditing } from 'admin-modules/build/helpers'

class TranslatableCategory extends Component {
  render() {
    const { title, slug, en } = this.props.data
    const isEdit = isEditing(this.props.location.pathname, this.props.match.params.slug)

    return (
      <Grid className="admin-item">
        <AdminHead
          title={this.props.title}
          onSave={this.props.onSave}
          onRemove={this.props.onRemove}
          saveText="Save"
          deleteText="Delete"
        />

        <Row className="underlined">
          <Col sm={8} smOffset={2}>
            <TranslatableTitleAndSlug
              titleVal={title}
              slugVal={slug}
              enTitleVal={en.title}
              enSlugVal={en.slug}
              onChange={this.props.onChange}
            />
          </Col>
        </Row>

        <Row className="underlined in-category">
          {isEdit && (
            <ItemsInCategory
              addLink={`/admin/items/${slug}/add`}
              slug="items"
            />
          )}
        </Row>
      </Grid>
    )
  }
}

TranslatableCategory.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }),
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

TranslatableCategory.defaultProps = {
  match: {
    params: {
      slug: '',
    },
  },
}

const initialState = {
  data: {
    title: '',
    slug: '',
    en: {
      title: '',
      slug: '',
    }
  },
}

export default withContentData(TranslatableCategory, initialState)
