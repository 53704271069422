"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var thumbs = _propTypes.default.arrayOf(_propTypes.default.shape({
  name: _propTypes.default.string.isRequired,
  width: _propTypes.default.number.isRequired,
  height: _propTypes.default.number.isRequired
}));

var newImageType = _propTypes.default.arrayOf(_propTypes.default.shape({
  original: _propTypes.default.string.isRequired,
  thumbs: thumbs.isRequired
}));

var oldImageType = _propTypes.default.arrayOf(_propTypes.default.string);

var _default = _propTypes.default.oneOfType([newImageType, oldImageType]);

exports.default = _default;