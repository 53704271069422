"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ListItems = require("../ListItems/ListItems");

var _withItemsInCategories = _interopRequireDefault(require("../../hoc/withItemsInCategories"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var ItemsInCategory = function ItemsInCategory(props) {
  return _react.default.createElement(_ListItems.ListItems, _extends({}, props, {
    addLink: "/items/add",
    itemEditUrlStart: "/items/edit/",
    title: "In this category"
  }));
};

ItemsInCategory.propTypes = {
  data: _propTypes.default.arrayOf(_propTypes.default.shape({
    _id: _propTypes.default.string.isRequired,
    title: _propTypes.default.string.isRequired
  })),
  location: _propTypes.default.shape({
    pathname: _propTypes.default.string.isRequired
  }).isRequired
};
ItemsInCategory.defaultProps = {
  data: []
};

var _default = (0, _withItemsInCategories.default)(ItemsInCategory);

exports.default = _default;