"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactRouterDom = require("react-router-dom");

var _reactBootstrap = require("react-bootstrap");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var remove = function remove(handleRemove, slug) {
  return function () {
    handleRemove(slug);
  };
};

var prevent = function prevent(e) {
  return e.preventDefault();
};

var SortableListItem = function SortableListItem(_ref) {
  var editUrl = _ref.editUrl,
      handleRemove = _ref.handleRemove,
      hideDelete = _ref.hideDelete,
      id = _ref.id,
      slug = _ref.slug,
      title = _ref.title;
  return _react.default.createElement("li", {
    className: "list-item",
    "data-id": id
  }, _react.default.createElement("a", {
    href: "",
    className: "handle",
    onClick: prevent
  }), _react.default.createElement(_reactRouterDom.Link, {
    to: editUrl,
    className: "title"
  }, title), !hideDelete && _react.default.createElement(_reactBootstrap.Button, {
    onClick: remove(handleRemove, slug),
    className: "delete",
    bsStyle: "danger",
    bsSize: "small"
  }, "Delete"));
};

SortableListItem.propTypes = {
  editUrl: _propTypes.default.string.isRequired,
  handleRemove: _propTypes.default.func.isRequired,
  hideDelete: _propTypes.default.bool,
  id: _propTypes.default.string.isRequired,
  slug: _propTypes.default.string.isRequired,
  title: _propTypes.default.string.isRequired
};
SortableListItem.defaultProps = {
  hideDelete: false
};
var _default = SortableListItem;
exports.default = _default;