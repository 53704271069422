"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.defaultError = void 0;

var _axios = _interopRequireDefault(require("axios"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var defaultError = function defaultError(err) {
  console.log('fetch failed :/', err);
};

exports.defaultError = defaultError;

var _default = function _default(url, callback) {
  var error = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultError;
  return _axios.default.get(url).then(callback).catch(error);
};

exports.default = _default;