"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSmallestImageFromSet = exports.comparator = void 0;

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

var comparator = function comparator(a, b) {
  if (a.width < b.width) return -1;
  if (a.width > b.width) return 1;
  return 0;
};

exports.comparator = comparator;

var getSmallestImageFromSet = function getSmallestImageFromSet(imgSet) {
  return _toConsumableArray(imgSet.sort(comparator))[0];
};

exports.getSmallestImageFromSet = getSmallestImageFromSet;