"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _default = function _default() {
  return {
    headers: {
      Authorization: "Bearer ".concat(localStorage.getItem('id_token'))
    }
  };
};

exports.default = _default;