"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _formik = require("formik");

var _classnames = _interopRequireDefault(require("classnames"));

var _elementReact = require("element-react");

var _Uploader = _interopRequireDefault(require("../Uploader/Uploader"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var Field = function Field(props) {
  if (props.config.type === 'imageUpload') return _react.default.createElement(_Uploader.default, {
    actionUrl: props.config.actionUrl,
    deleteUrl: props.config.deleteUrl,
    imagesList: props.imagesList,
    maxFileSize: props.config.maxFileSize,
    onFileUploadStart: function onFileUploadStart() {},
    onFinishedAllUploads: function onFinishedAllUploads() {},
    onChange: props.onImagesChange,
    onFileSizeLimit: function onFileSizeLimit() {
      console.log('file size limit');
    }
  });
  return _react.default.createElement(_formik.Field, {
    name: props.config.id,
    render: function render(_ref) {
      var field = _ref.field;
      var hasError = props.isTouched && props.error;
      var config = props.config;
      return _react.default.createElement(_react.Fragment, null, props.config.label && _react.default.createElement("p", {
        className: "label"
      }, config.label, config.requiredLabelText && _react.default.createElement("span", {
        className: "required-label-text"
      }, config.requiredLabelText)), _react.default.createElement(_elementReact.Input, _extends({}, field, {
        placeholder: props.config.placeholder,
        type: props.config.type,
        onChange: field.onChange(props.config.id),
        className: (0, _classnames.default)({
          'has-error': hasError
        })
      })), config.note && _react.default.createElement("p", {
        className: "note-container"
      }, config.note), _react.default.createElement("div", {
        className: "error-container"
      }, hasError && _react.default.createElement("p", {
        className: "error-message"
      }, props.error)));
    }
  });
};

var _default = Field;
exports.default = _default;