import React from 'react'
import PT from 'prop-types'
import { Grid, Row, Col } from 'react-bootstrap'
import withContentData from 'admin-modules/build/hoc/withContentData'
import AdminHead from 'admin-modules/build/components/AdminHead/AdminHead'
import TranslatableContent from 'admin-modules/build/components/Form/TranslatableContent'
import categoryProp from 'admin-modules/build/proptypes/categoryProp'
import imagesProp from 'admin-modules/build/proptypes/images'
import CatSelector from 'admin-modules/build/components/Item/CategorySelector'
import Uploader from 'admin-modules/build/components/Uploader/Uploader'
import Locations from './Locations/Locations'

const Place = ({
  data, onChange, onRemove, onSave, title,
}) => (
  <Grid className={`admin-item ${data.slug}`}>
    <AdminHead
      title={title}
      onSave={onSave}
      onRemove={onRemove}
      saveText="Save"
      deleteText="Delete"
    />
    <TranslatableContent
      titleVal={data.title}
      slugVal={data.slug}
      content={data.content}
      enTitleVal={data.en ? data.en.title : ''}
      enSlugVal={data.en ? data.en.slug : ''}
      enContent={data.en ? data.en.content : ''}
      onChange={onChange}
    />
    <Row className="underlined admin-categories">
      <Col sm={8} smOffset={2}>
        <CatSelector
          itemCategories={data.categories}
          onChange={onChange}
        />
      </Col>
    </Row>
    <Row className="underlined photos-row">
      <Col sm={8} smOffset={2}>
        <Uploader
          title="Photos"
          images={data.images}
          onChange={onChange}
          folder="places"
          usePublicUrl
        />
      </Col>
    </Row>
    <Row className="underlined">
      <Col sm={8} smOffset={2}>
        <Locations
          title="Lokácie"
          data={data.locations}
          onChange={onChange}
        />
      </Col>
    </Row>
  </Grid>
)

Place.propTypes = {
  data: PT.shape({
    title: PT.string,
    slug: PT.string,
    content: PT.string,
    categories: categoryProp,
    images: imagesProp,
    en: PT.shape({
      title: PT.string,
      slug: PT.string,
      content: PT.string,
    }).isRequired,
  }).isRequired,
  onChange: PT.func.isRequired,
  onRemove: PT.func.isRequired,
  onSave: PT.func.isRequired,
  title: PT.string.isRequired,
}

const initialState = {
  data: {
    title: '',
    slug: '',
    content: '',
    categories: [],
    images: [],
    en: {
      title: '',
      slug: '',
      content: '',
    },
  },
}

export default withContentData(Place, initialState)
