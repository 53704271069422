"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ListItems = _interopRequireDefault(require("../ListItems/ListItems"));

var _withContentData = _interopRequireDefault(require("../../hoc/withContentData"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var CategoryList = function CategoryList(_ref) {
  var title = _ref.title;
  return _react.default.createElement(_ListItems.default, {
    hideDelete: true,
    title: title
  });
};

CategoryList.propTypes = {
  title: _propTypes.default.string.isRequired
};

var _default = (0, _withContentData.default)(CategoryList);

exports.default = _default;