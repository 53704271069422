"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Callback = function Callback() {
  return _react.default.createElement("p", null, "logging in...");
};

var _default = Callback;
exports.default = _default;