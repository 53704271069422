import React from 'react'
import PT from 'prop-types'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Grid, Col, Row, Table } from 'react-bootstrap'
import { getFetchUrl } from 'admin-modules/build/helpers/url'
// import './ItemsInCategory.css'

class CategoryItemsFilter extends React.Component {
  state = {
    items: [],
  };

  componentDidMount() {
    const url = getFetchUrl(window.location.pathname)

    axios.get(url).then((res) => {
      this.setState({
        items: res.data,
      })
    })
  }

  render() {
    return (
      <Grid className="category-items-filter">
        <Row>
          <Col sm={8} smOffset={2} className="head">
            <Row>
              <Col sm={6}>
                <h3 className="title">{this.props.title}</h3>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col sm={8} smOffset={2} className="head">
            <Table striped bordered condensed hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {this.state.items.map((item, index) => (
                  <tr key={item._id}>
                    <td>
                      <p>{index + 1}</p>
                    </td>
                    <td>
                      <Link to={`/verified-submissions/${item.slug}`}>{item.name}</Link>
                    </td>
                    <td>
                      <span>{item.email}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>

      </Grid>
    )
  }
}

CategoryItemsFilter.propTypes = {
  title: PT.string.isRequired,
}

export default CategoryItemsFilter
