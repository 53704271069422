"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactRouterDom = require("react-router-dom");

var _Callback = _interopRequireDefault(require("./Callback"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var handleAuthentication = function handleAuthentication(auth, _ref) {
  var location = _ref.location,
      history = _ref.history;

  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication(history);
  }
};

var Login = function Login(_ref2) {
  var auth = _ref2.auth;
  return _react.default.createElement(_reactRouterDom.Route, {
    path: "/login",
    render: function render(props) {
      handleAuthentication(auth, props);
      return _react.default.createElement(_Callback.default, props);
    }
  });
};

Login.propTypes = {
  auth: _propTypes.default.shape({
    handleAuthentication: _propTypes.default.func.isRequired
  }).isRequired
};
var _default = Login;
exports.default = _default;