"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactBootstrap = require("react-bootstrap");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var AdminHead = function AdminHead(_ref) {
  var deleteText = _ref.deleteText,
      onRemove = _ref.onRemove,
      onSave = _ref.onSave,
      saveText = _ref.saveText,
      title = _ref.title;
  return _react.default.createElement(_reactBootstrap.Row, {
    className: "underlined"
  }, _react.default.createElement(_reactBootstrap.Col, {
    sm: 8,
    smOffset: 2
  }, _react.default.createElement(_reactBootstrap.Row, null, _react.default.createElement(_reactBootstrap.Col, {
    xs: 6
  }, _react.default.createElement("h3", null, title)), _react.default.createElement(_reactBootstrap.Col, {
    xs: 6,
    className: "clearfix"
  }, _react.default.createElement(_reactBootstrap.Button, {
    bsStyle: "success",
    bsSize: "small",
    onClick: onSave
  }, saveText), _react.default.createElement(_reactBootstrap.Button, {
    onClick: onRemove,
    className: "delete",
    bsStyle: "link",
    bsSize: "small"
  }, deleteText)))));
};

AdminHead.propTypes = {
  deleteText: _propTypes.default.string.isRequired,
  onRemove: _propTypes.default.func.isRequired,
  onSave: _propTypes.default.func.isRequired,
  saveText: _propTypes.default.string.isRequired,
  title: _propTypes.default.string.isRequired
};
var _default = AdminHead;
exports.default = _default;