"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRouterDom = require("react-router-dom");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Auth = _interopRequireDefault(require("./Auth"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var PrivateRoute = function PrivateRoute(_ref) {
  var Component = _ref.Component,
      login = _ref.login,
      rest = _objectWithoutProperties(_ref, ["Component", "login"]);

  return _react.default.createElement(_reactRouterDom.Route, _extends({}, rest, {
    render: function render(props) {
      if (_Auth.default.isAuthenticated()) return _react.default.createElement(Component, _extends({}, rest, props));
      login();
      return null;
    }
  }));
};

PrivateRoute.propTypes = {
  Component: _propTypes.default.func.isRequired,
  login: _propTypes.default.func.isRequired,
  path: _propTypes.default.string.isRequired
};
var _default = PrivateRoute;
exports.default = _default;