"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getItemsPatchUrl = exports.getDeleteUrl = exports.getPatchUrl = exports.getPostUrl = exports.getItemsInCategoriesFetchUrl = exports.getAllCategories = exports.getAllItems = exports.getFetchUrl = exports.getLastUrlSegment = exports.getContentTypeSlug = void 0;

var _constants = require("../constants");

var getContentTypeSlug = function getContentTypeSlug(path) {
  return path.split('/')[1];
};

exports.getContentTypeSlug = getContentTypeSlug;

var getLastUrlSegment = function getLastUrlSegment(path) {
  var segments = path.split('/');
  return segments[segments.length - 1];
};

exports.getLastUrlSegment = getLastUrlSegment;

var getUrl = function getUrl(path, slug) {
  return "".concat(_constants.SERVER_URL, "/api/").concat(getContentTypeSlug(path), "/").concat(slug);
};

var getFetchUrl = function getFetchUrl(path) {
  var slug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var contentTypeSlug = getContentTypeSlug(path); // items api has exception because item can belong to multiple categories

  if (contentTypeSlug === 'items' && slug !== '') {
    return "".concat(_constants.SERVER_URL, "/api/").concat(contentTypeSlug, "/single/").concat(slug);
  }

  return getUrl(path, slug);
};

exports.getFetchUrl = getFetchUrl;

var getAllItems = function getAllItems() {
  return "".concat(_constants.SERVER_URL, "/api/items");
};

exports.getAllItems = getAllItems;

var getAllCategories = function getAllCategories() {
  return "".concat(_constants.SERVER_URL, "/api/categories");
};

exports.getAllCategories = getAllCategories;

var getItemsInCategoriesFetchUrl = function getItemsInCategoriesFetchUrl(slug) {
  return "".concat(_constants.SERVER_URL, "/api/items/").concat(slug);
}; // atm same as getFetchUrl, api has to be refactored for proper rest...


exports.getItemsInCategoriesFetchUrl = getItemsInCategoriesFetchUrl;

var getPostUrl = function getPostUrl(path, slug) {
  return getUrl(path, slug);
};

exports.getPostUrl = getPostUrl;

var getPatchUrl = function getPatchUrl(path, slug) {
  return getUrl(path, slug);
};

exports.getPatchUrl = getPatchUrl;

var getDeleteUrl = function getDeleteUrl(path, slug) {
  return getUrl(path, slug);
};

exports.getDeleteUrl = getDeleteUrl;

var getItemsPatchUrl = function getItemsPatchUrl(slug) {
  return "".concat(_constants.SERVER_URL, "/api/items/").concat(slug);
};

exports.getItemsPatchUrl = getItemsPatchUrl;