import PT from 'prop-types'

export const markerShape = PT.shape({
  id: PT.string.isRequired,
  lat: PT.number.isRequired,
  lng: PT.number.isRequired,
  title: PT.string.isRequired,
  text: PT.string.isRequired,
  image: PT.string,
  imgRatio: PT.number,
  categorySlug: PT.arrayOf(PT.string.isRequired).isRequired,
  slug: PT.string.isRequired,
})

export const latLngShape = PT.shape({
  lat: PT.number.isRequired,
  lng: PT.number.isRequired,
});
