import React from 'react'
import PT from 'prop-types'
import { Tabs, Tab, Button } from 'react-bootstrap'
import LocationTab from './LocationTab'
import './Locations.sass'

const getTitle = (location) => {
  if (location.desc) {
    if (location.desc.length > 12) {
      return `${location.desc.substring(0, 10)}...`
    }
    return location.desc
  }
  return 'Nová'
}

class Locations extends React.Component {
  static getDerivedStateFromProps(props, state) {
    // after deleting tab, go to first tab
    if (state.key > props.data.length - 1) {
      return { key: 0 }
    }
    return null
  }

  state = {
    key: 0,
  }

  addLocation = () => {
    const empty = {
      desc: '',
      enDesc: '',
      lat: 0,
      lng: 0,
    }
    const { data } = this.props
    const index = data.length
    this.props.onChange({
      locations: [ ...data, ...[empty] ]
    })
    this.setState({ key: index })
  }

  onChange = (data, index) => {
    const updated = [...this.props.data]

    if (data === null) {
      updated.splice(index, 1)
    } else {
      updated[index] = {
        ...updated[index],
        ...data,
      }
    }

    this.props.onChange({
      locations: updated,
    })
  }

  render() {
    return (
      <div className="locations-admin">
        <h3>{this.props.title}</h3>
        <Button
          onClick={this.addLocation}
          className="add-location"
          bsStyle="success"
          bsSize="small"
        >Pridať
        </Button>
        <Tabs
          activeKey={this.state.key}
          onSelect={key => this.setState({ key })}
          bsStyle="tabs"
          animation={false}
          id="translatable-title-and-slug"
        >
          {this.props.data.map((location, index) => (
            <Tab
              key={index}
              eventKey={index}
              title={getTitle(location)}
            >
              <LocationTab
                index={index}
                key={this.state.key}
                data={location}
                onChange={this.onChange}
              />
            </Tab>
          ))}
        </Tabs>
      </div>
    )
  }
}

Locations.propTypes = {
  data: PT.arrayOf(PT.shape({
    desc: PT.string.isRequired,
    enDesc: PT.string.isRequired,
    lat: PT.number.isRequired,
    lng: PT.number.isRequired,
  })),
  onChange: PT.func.isRequired,
  title: PT.string.isRequired,
}

Locations.defaultProps = {
  data: [],
}

export default Locations
