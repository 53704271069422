import React from 'react'
import PT from 'prop-types'
import { Grid, Row, Col } from 'react-bootstrap'
import withContentData from 'admin-modules/build/hoc/withContentData'
import imagesProp from 'admin-modules/build/proptypes/images'
import { PUBLIC_URL } from 'admin-modules/build/constants'
import { MPBAMap } from '../map/MPBAMap'
import './Tip.sass'

const TipDetail = ({ data }) => {
  const { slug, name, email, description, photos, mapCenter } = data
  const markers = mapCenter ? [mapCenter] : []
  const center = mapCenter || { lat: 48.1491494, lng: 17.0545824 }

  return (
    <>
      <div className="tip-map-container">
        <MPBAMap
          markers={markers}
          balloons={[]}
          center={center}
          zoom={12}
          isMobileLayout={window.innerWidth < 768}
        />
      </div>
      <Grid className={`admin-item ${slug}`}>
        <Row>
          <Col sm={8} smOffset={2}>
            <h2>{name}</h2>
            <p>od: {email}</p>
            <p>{description}</p>
            
            {photos.map(img => (
              <img
                key={img.original}
                style={{
                  maxWidth: '100%',
                  marginTop: 20,
                  marginBottom: 20,
                }}
                className="tip-photo"
                src={`${PUBLIC_URL}/uploads/tips/${img.original}`}
                alt=""
              />
            ))}
          </Col>
        </Row>
      </Grid>
    </>
  )
}

TipDetail.propTypes = {
  data: PT.shape({
    title: PT.string,
    slug: PT.string,
    description: PT.string,
    photos: imagesProp,
  })
}

TipDetail.defaultProps = {
  data: {
    photos: []
  }
}

export default withContentData(TipDetail)
