"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constants = require("admin-modules/build/constants");

var _patch = _interopRequireDefault(require("./patch"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _default = function _default(categorySlug, slug) {
  var url = "".concat(_constants.SERVER_URL, "/api/categories/removeFrom/").concat(categorySlug, "/").concat(slug);
  return (0, _patch.default)(url, null);
};

exports.default = _default;