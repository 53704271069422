"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ListItems = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactRouterDom = require("react-router-dom");

var _reactBootstrap = require("react-bootstrap");

var _SortableList = _interopRequireDefault(require("../SortableList/SortableList"));

var _withContentData = _interopRequireDefault(require("../../hoc/withContentData"));

var _url = require("../../helpers/url");

require("./ListItems.css");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
} // don't list uncategorized items in categories
// if (nextState.slug === 'categories') {
//   nextState.items = nextState.items.filter(item => item.slug !== '_uncategorized')
// }


var ListItems = function ListItems(_ref) {
  var addLink = _ref.addLink,
      data = _ref.data,
      location = _ref.location,
      onRemove = _ref.onRemove,
      onReorder = _ref.onReorder,
      title = _ref.title,
      rest = _objectWithoutProperties(_ref, ["addLink", "data", "location", "onRemove", "onReorder", "title"]);

  var contentTypeSlug = (0, _url.getContentTypeSlug)(location.pathname);
  var addNewLink = addLink || "/".concat(contentTypeSlug, "/add");
  return _react.default.createElement(_reactBootstrap.Grid, {
    className: "list-items"
  }, _react.default.createElement(_reactBootstrap.Row, null, _react.default.createElement(_reactBootstrap.Col, {
    sm: 8,
    smOffset: 2,
    className: "head"
  }, _react.default.createElement("h3", {
    className: "title"
  }, title), _react.default.createElement(_reactRouterDom.Link, {
    to: addNewLink
  }, _react.default.createElement(_reactBootstrap.Button, {
    className: "add",
    bsStyle: "success",
    bsSize: "small"
  }, "Add")))), _react.default.createElement(_reactBootstrap.Row, null, _react.default.createElement(_reactBootstrap.Col, {
    sm: 8,
    smOffset: 2
  }, _react.default.createElement(_SortableList.default, _extends({
    items: data,
    handleRemove: onRemove,
    onReorder: onReorder,
    editUrlStart: "/".concat(contentTypeSlug, "/edit/")
  }, rest)))));
};

exports.ListItems = ListItems;
ListItems.propTypes = {
  addLink: _propTypes.default.string,
  data: _propTypes.default.arrayOf(_propTypes.default.shape({
    _id: _propTypes.default.string.isRequired,
    title: _propTypes.default.string.isRequired
  })),
  location: _propTypes.default.shape({
    pathname: _propTypes.default.string.isRequired
  }).isRequired,
  onRemove: _propTypes.default.func.isRequired,
  onReorder: _propTypes.default.func.isRequired,
  title: _propTypes.default.string.isRequired
};
ListItems.defaultProps = {
  addLink: '',
  data: []
};

var _default = (0, _withContentData.default)(ListItems);

exports.default = _default;