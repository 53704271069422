import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { TextInput } from 'admin-modules/build/components/Form'
import MPBAMap from '../map/MPBAMap';

export default class LocationTab extends React.Component {
  onDragEnd = ({ center }) => {
    this.props.onChange({
      lat: center.lat,
      lng: center.lng,
    }, this.props.index)
  }
  
  handleChange = (data) => {
    this.props.onChange(data, this.props.index)
  }
  
  handleRemove = () => {
    const confirm = window.confirm(`Zmazať lokáciu? ${ this.props.data.desc}`)
    if (confirm) {
      this.props.onChange(null, this.props.index)
    }
  }

  render() {
    const mapCenter = (this.props.data.lat && this.props.data.lng)
      ? {
          lat: parseFloat(this.props.data.lat),
          lng: parseFloat(this.props.data.lng),
        }
      : { 
          lat: 48.1491494,
          lng: 17.0545824
        }

    return (
      <Row>
        <Col xs={12}>
          <Button
            onClick={this.handleRemove}
            className="delete"
            bsStyle="link"
            bsSize="small"
          >Zmazať túto lokáciu
          </Button>
          <TextInput
            controlId="desc"
            label="Popis"
            value={this.props.data.desc}
            onChange={this.handleChange}
            contentKey="desc"
          />
          <TextInput
            controlId="enDesc"
            label="EN popis"
            value={this.props.data.enDesc}
            onChange={this.handleChange}
            contentKey="enDesc"
          />
        </Col>
        <Col xs={12}>
          <MPBAMap
            markers={[]}
            balloons={[]}
            center={mapCenter}
            zoom={12}
            onMapChange={this.onDragEnd}
            crosshair
            isMobileLayout={window.innerWidth < 768}
          />
        </Col>
      </Row>
    )
  }
}
