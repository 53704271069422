"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _authorization = _interopRequireDefault(require("./authorization"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _default = function _default(url, data) {
  var options = (0, _authorization.default)();
  return _axios.default.patch(url, data, options);
};

exports.default = _default;