import React from 'react'
import { BrowserRouter, Redirect } from 'react-router-dom'
import { Header, CategoryList, CategoryItemsFilter } from 'admin-modules/build/components'
import { Auth, Login as LoginRedirect, PrivateRoute } from 'admin-modules/build/auth'
import ItemList from 'admin-modules/build/components/Item/ItemList'
import 'admin-modules/build/styles/custom_bootstrap.css'
import 'admin-modules/build/styles/adminStyles.css'
import Place from './components/Place'
import naviItems from './naviItems'
import TranslatableCategory from './components/TranslatableCategory'
import TipsList from './components/Tip/TipsList'
import TipDetail from './components/Tip/TipDetail'
import Page from './components/Page'
import Texts from './components/Texts'

const auth = new Auth(
  process.env.REACT_APP_AUTH0_CLIENT_ID,
  process.env.REACT_APP_AUTH0_DOMAIN,
)

const App = () => (
  <BrowserRouter>
    <div className="App">
      <Header auth={auth} title="M_P_ BA" naviItems={naviItems} />

      <div className="main-container">
        <PrivateRoute
          exact
          path="/"
          Component={() => <Redirect to="/categories" />}
          login={auth.login}
        />

        {/* categories */}
        <PrivateRoute exact path="/categories" Component={CategoryList} title="Kategórie" login={auth.login} />
        <PrivateRoute path="/categories/add" Component={TranslatableCategory} title="Nová kategória" login={auth.login} />
        <PrivateRoute path="/categories/edit/:slug" Component={TranslatableCategory} title="Editovať kategóriu" login={auth.login} />

        {/* places */}
        <PrivateRoute exact path="/items" Component={CategoryItemsFilter} title="Pekné miesta" login={auth.login} />
        <PrivateRoute path="/items/add" Component={Place} title="Pridať miesto" login={auth.login} />
        <PrivateRoute path="/items/edit/:slug" Component={Place} title="Editovať miesto" login={auth.login} />

        {/* pages */}
        <PrivateRoute exact path="/pages" Component={ItemList} login={auth.login} title="Pages" />
        <PrivateRoute path="/pages/add" Component={Page} login={auth.login} title="Add Page" />
        <PrivateRoute path="/pages/edit/:slug" Component={Page} login={auth.login} title="Edit Page" />

        <PrivateRoute exact path="/verified-submissions" Component={TipsList} title="Tipy" login={auth.login} />
        <PrivateRoute exact path="/verified-submissions/:slug" Component={TipDetail} title="Správa tipov" login={auth.login} />

        <PrivateRoute exact path="/texts/edit" Component={Texts} title="Správa textov" login={auth.login} />

        <LoginRedirect auth={auth} />
      </div>
    </div>
  </BrowserRouter>
)

export default App
