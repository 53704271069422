"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getValidation = exports.getInitialValues = void 0;

var getInitialValues = function getInitialValues(config) {
  if (!config || !config.fields) return null;
  var vals = {};
  config.fields.forEach(function (_ref) {
    var id = _ref.id,
        initialValue = _ref.initialValue;
    vals[id] = initialValue;
  });
  return vals;
};

exports.getInitialValues = getInitialValues;

var getValidation = function getValidation(fields) {
  return function (values) {
    var errors = {};
    fields.forEach(function (f) {
      // field validation is not required
      if (!f.validation) return;
      var error = f.validation(values[f.id]);

      if (error) {
        errors[f.id] = error;
      }
    });
    return errors;
  };
};

exports.getValidation = getValidation;